<template>
	<div class="box">
		<!-- 地址 -->
		<div class="topAddress">
			<div class="address">
				<div class="infoAddress">正商城裕园一号院（建设中）-1号楼正商城 裕园1号院1单元1525</div>
				<div class="telMy">
					<p>联系我们 15298556059</p>
					<button>到店</button>
				</div>
			</div>
		</div>
		<div>
			<van-cell title="用餐时间" is-link value="11:11" />
			<van-cell title="用餐桌号" is-link value="2号桌" />
		</div>
		<!-- 商品 -->
		<div class="shopping">
			<div class='container'>
				<!-- <div class="content display_flex flex-wrap_wrap"> -->
				<div class="content-item" v-for="(item, index) in list" :key="index">
					<ul class="display_flex flex-direction_column" v-show="isOpen || index < max">
						<li class="label">
							<div class="left">
								<img :src=item.image alt="">
								<div style="margin-left: 10px;">{{ item.label }}</div>
							</div>

							<div class="right">
								<p class="p1">￥{{item.value}}</p>
								<p class="p2">x{{item.count}}</p>
							</div>
						</li>
						<!-- <li class="value"><span>{{ item.value }}</span></li> -->
					</ul>
				</div>
				<!-- </div> -->
				<button class="btnMoer" v-show="!isOpen && list.length > max" @click="isOpen = !isOpen">展开更多</button>
				<button class="btnMoer" v-show="isOpen && list.length > max" @click="isOpen = !isOpen">收起</button>
			</div>
			<!-- 优惠券 -->
			<div>
				<!-- 优惠券单元格 -->
				<van-coupon-cell :coupons="state.coupons" :chosen-coupon="state.chosenCoupon"
					@click="state.showList = true" />
				<!-- 优惠券列表 -->
				<van-popup v-model:show="state.showList" round position="bottom" style="height: 90%; padding-top: 4px;">
					<van-coupon-list :coupons="state.coupons" :chosen-coupon="state.chosenCoupon"
						:disabled-coupons="disabledCoupons" @change="onChange" @exchange="onExchange" />
				</van-popup>
			</div>
			<van-cell-group>
			  <van-cell title="满减优惠" value="-￥3" />
			</van-cell-group>
			<div style="text-align: right;margin-right: 20px;">
				<span>已优惠￥<span>3</span></span>
				<span>小计￥<span style="color: #FC6228;">33</span></span>
			</div>
		</div>
		<!--备注  -->
		<div class="rank">
			<van-field v-model="value" label="备注" placeholder="备注口味，包装，配送要求等" input-align="right" />
		</div>
		<!-- 支付 -->
		<div class="pay">
			<p>合计: <span>￥987</span></p>
			<button>支付</button>
		</div>
	</div>
</template>

<script>
	import { reactive } from 'vue';
	const coupon = {
	  available: 1,
	  condition: '无使用门槛\n最多优惠12元',
	  reason: '',
	  value: 150,
	  name: '优惠券名称',
	  startAt: 1489104000,
	  endAt: 1514592000,
	  valueDesc: '1.5',
	  unitDesc: '元',
	};

	export default {
		setup(){
			   const state = reactive({
			      coupons: [coupon],
			      showList: false,
			      chosenCoupon: -1,
			    });
			
			    const onChange = (index) => {
			      state.showList = false;
			      state.chosenCoupon = index;
			    };
			    const onExchange = (code) => {
			      state.coupons.push(coupon);
			    };
				 return {
				      state,
				      onChange,
				      onExchange,
				      disabledCoupons: [coupon],
				    };
		},
		data() {
			return {
				max: 2, // 默认最多显示的个数；最大行数*每行显示的个数 Number 
				isOpen: false, // 是否展开全部信息的标识 Boolean 默认false
				list: [{
						label: '必选-特辣锅底',
						value: 254,
						image: "https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg",
						count: 1
					},
					{
						label: '韩国炒年糕网红款（微辣）',
						value: 346,
						image: "https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg",
						count: 1
					},
					{
						label: '必选-特辣锅底',
						value: 54,
						image: "https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg",
						count: 1
					},
					{
						label: '韩国炒年糕网红款（微辣）',
						value: 234,
						image: "https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg",
						count: 1
					},
				],
			}
		},
		methods: {
			
		},
		mounted() {

		},
		watch: {

		}
	}
</script>

<style lang="less" scoped>
	p {
		margin: 0;
		padding: 0;
	}

	.box {
		width: 100%;

		.topAddress {
			background: #FC6228;
			height: 160px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.address {
				width: 88%;
				height: 89px;
				background: #FFFFFF;
				border-radius: 9px;
				margin-top: 50px;
				font-size: 14px;
				display: flex;
				flex-direction: column;
				// align-items: center;
				justify-content: center;
				padding: 0 10px;

				.infoAddress {}

				.telMy {
					margin-top: 5px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					p {
						font-size: 11px;
						color: #999999;
					}

					button {
						width: 61px;
						height: 28px;
						background: #FC6228;
						border-radius: 14px;
						border: none;
						color: #FFFFFF;
					}
				}
			}
		}

		// 
		.shopping {
			font-size: 14px;
			background-color: #FFFFFF;
			margin: 15px 0;

			.container {
				padding: 10px;
				display: flex;
				flex-direction: column;

				.content-item {
					margin-bottom: 10px;

					.label {
						display: flex;
						justify-content: space-between;

						.left {
							display: flex;

							img {
								width: 45px;
								height: 45px;
								border-radius: 5px;
							}
						}

						.right {
							.p1 {
								font-size: 13px;
								font-family: Microsoft YaHei;
								font-weight: bold;
								color: #1A1A1A;
							}

							.p2 {
								font-size: 11px;
								font-family: PingFang SC;
								font-weight: bold;
								color: #999999;
							}
						}

					}
				}

				.btnMoer {
					border: none;
					background: #FFFFFF;
				}
			}

		}

		// 
		.rank {
			margin-top: 10px;
		}

		// 
		.pay {
			width: 100%;
			height: 51px;
			background: #FFFFFF;
			position: fixed;
			bottom: 0;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			p {
				margin-left: 10px;

				span {
					color: #FC6228;
				}
			}

			button {
				margin-right: 10px;
				width: 87px;
				height: 34px;
				background: #FC6228;
				border-radius: 17px;
				border: none;
				color: #FFFFFF;
			}
		}
	}
</style>
